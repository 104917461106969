@import url("https://fonts.googleapis.com/css2?family=Afacad:wght@400;500;600;700&display=swap");

::-webkit-scrollbar {
  width: 7px;
  border-radius: 2rem;
}

::-webkit-scrollbar-thumb {
  background-color: #dfdfdf1e;
  border-radius: 2rem;
}

::-webkit-scrollbar-track {
  background-color: rgb(0 0 0 / 40%);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #dfdfdfa2;
}
body {
  background: radial-gradient(circle, #292b2f 0, #000 100%);
  font-family: "Afacad", sans-serif;
  color: white;

  a {
    text-decoration: none;
    color: white;
  }
  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90svh;
    .row {
      display: flex;
      .projects-title {
        h2 {
          text-align: center;
          margin-bottom: 3rem;
        }
      }
      .hero-text {
        display: flex;
        align-items: center;
        justify-content: center;
        .name {
          margin-bottom: 1rem;
          span {
            font-size: 3rem;
            &.w,
            &.a {
              background: linear-gradient(90deg, #3b3b3b, #ffd478);
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              display: inline-block;
            }
          }
        }

        .full-stack {
          border-left: solid 1px #272727;
          padding-left: 10px;
          color: white;
          margin-bottom: 0.5rem;
        }
        .my-stack {
          color: #ffffffb2;
          margin-bottom: 1px;
          svg {
            color: rgb(255 255 255 / 17%);
            transition: all 0.2s;
            margin: 0 1px;
            font-size: 20px;
            &:hover {
              color: white;
            }
          }
        }
      }
      .projects {
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        overflow-y: auto;
        scroll-behavior: smooth;
        max-height: 490px;
        &:has(.project:hover) > .project:not(:hover) {
          opacity: 0.2;
          transition: all 0.5s;
          filter: blur(3px);
        }
        .project {
          border: 1px solid hsl(0deg 0% 100% / 5%);
          // border-image: linear-gradient(to right, #1a1a1a, #ffc240);
          // border-image-slice: 1;
          padding: 10px;
          margin: 10px;
          border-radius: 1rem;
          text-align: center;
          background: #0000006e;
          transition: all 0.5s;
          opacity: 1;
          filter: blur(0px);

          > a {
            height: 100%;
            display: block;
            padding: 10px;
            transition: padding 0.5s;
            .project-div {
              display: grid;
              align-content: space-between;
              height: 100%;
              p {
                color: #bfbfbf;
              }
              .stack {
                border-top: 1px solid #ffffff0d;
                padding-top: 5px;
                font-size: 20px;
                svg {
                  margin: 0 5px;
                }
              }
            }
          }
          &.github {
            grid-column: span 2;
          }
          &:hover {
            border: solid 1px rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .hero {
    .row {
      .projects-title {
        h2 {
          margin: 1rem 0 !important;
        }
      }
      .projects {
        grid-template-columns: 1fr !important;
        justify-content: start !important;
        grid-auto-flow: column;
        grid-auto-columns: minmax(260px, 1fr);
        overflow-x: auto;
        overflow-y: visible;
        .project {
          a {
            min-width: 200px;
            min-height: 260px;
          }
        }
      }

      .hero-text {
        justify-content: start !important;
        .name,
        .hello {
          text-align: center;
          width: 100vw;
        }
        .name {
          span {
            font-size: 2.5rem !important;
          }
        }
      }
    }
  }
}
