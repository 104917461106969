.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
  border-bottom: 1px solid #41414161;
}

.header-icons {
  display: flex;
  align-items: center;
}

.header h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

.header a {
  margin: 0 3.5rem;
  font-size: 26px;
  transition: all 0.2s;
}

.header a:hover {
  margin: 0 4rem;
}
.header-text {
  margin: 0px 20px;
  a {
    font-size: 15px;
  }
}
@media screen and (max-width: 768px) {
  .header a {
    margin: 0 2rem;
  }
}
